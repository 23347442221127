<template>
  <b-sidebar
    v-if="isSidebarActive"
    :visible="isSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm()"
    @change="updateIsSidebarActive($event)">
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          Ticket Type Detail
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>

      <!-- BODY -->
      <ValidationObserver
        #default="{ handleSubmit }"
        ref="TicketContactDetailForm">
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm()">
          <TextFieldInput
            v-model="ticketType.code"
            name="ticketTypeCode"
            label="Code"
            :rules="{ required: true, max: 3, min: 3 }"
            maxlength="3"
            trim
          />
          <TextFieldInput
            v-model="ticketType.name"
            name="Name"
            label="Name"
            :rules="{ required: true, max: 100 }"
            maxlength="100"
            trim
          />
          <TextFieldInput
            v-model="ticketType.remark"
            name="Remark"
            label="Remark"
            trim
          />
          <b-form-group label="Required Category">
            <b-form-checkbox
              v-model="ticketType.isRequiredCategory"
              class="custom-control-success d-inline-block"
              name="check-button"
              switch />
          </b-form-group>
          <StatusRadioButton :status.sync="ticketType.status" />
          <b-row>
            <b-col>
              Created At : {{ ticketType.createdAt | formatDateTimeEn }}
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              Created By : {{ ticketType.createdBy.username }}
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              Updated At : {{ ticketType.updatedAt | formatDateTimeEn }}
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              Updated By : {{ ticketType.updatedBy.username }}
            </b-col>
          </b-row>
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2 btn-submit"
              type="submit"
              :disabled="compareOldValue"
            >
              Save
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              class="btn-submit"
              variant="outline-secondary"
              :disabled="compareOldValue"
              @click="resetForm()"
            >
              Cancel
            </b-button>
            <b-button
              variant="outline-danger"
              class="btn-icon ml-auto"
              @click="deleteItem ()"
            >
              <feather-icon icon="Trash2Icon" />
            </b-button>
          </div>
        </b-form>
      </ValidationObserver>
    </template>
  </b-sidebar>
</template>

<script>
import TextFieldInput from '@/components/Form/TextFieldInput.vue'
import StatusRadioButton from '@/components/Form/StatusRadioButton.vue'

export default {
  components: { TextFieldInput, StatusRadioButton },
  model: {
    prop: 'isSidebarActive',
    event: 'update:is-sidebar-active'
  },
  props: {
    isSidebarActive: {
      type: Boolean,
      default: false
    },
    initialTicketType: {
      type: Object,
      required: true
    },
    selectedItem: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
    }
  },
  computed: {
    ticketType: {
      get () {
        return this.selectedItem
      },
      set (val) {
        this.$emit('update:selected-item', val)
      }
    },
    compareOldValue () {
      return JSON.stringify(this.ticketType) === JSON.stringify(this.initialTicketType)
    }
  },
  methods: {
    updateIsSidebarActive (val) {
      this.$emit('update:is-sidebar-active', val)
    },
    onSubmit () {
      const payload = {
        code: this.ticketType.code,
        id: this.ticketType.id,
        name: this.ticketType.name,
        remark: this.ticketType.remark,
        isRequiredCategory: this.ticketType.isRequiredCategory,
        status: this.ticketType.status
      }
      this.$emit('update-ticket-type', payload)
    },
    resetForm () {
      this.ticketType = { ...this.initialTicketType }
      this.$refs.TicketTypeDetailForm.reset()
    },
    deleteItem () {
      this.$emit('delete-item', {
        ID: this.ticketType.id,
        Name: this.ticketType.name
      })
    }
  }
}
</script>

<style>

</style>
